// WRITE ARTICLE
export const marketColdStart = {
  order: 0,
  name: "New Market Playbook",
  keywords: "missing location city submarket empty create",
  subcategory: "Discover more",
  markdown: `# New Market Playbook

  ## Populate the map

  ## Organize properties

  ## Ask for help`,
};
